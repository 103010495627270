import {AfterViewInit, Directive, ElementRef, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

const $ = require('jquery');

@Directive({
  selector: '[appNgxSlickCarousel]',
  exportAs: 'ngx-slick-carousel'
})
export class NgxSlickCarouselDirective implements AfterViewInit {
  private el;

  constructor(el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    this.el = el;
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      $(this.el.nativeElement).slick();
    }
  }

}
