/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./how-it-works-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./how-it-works-dialog.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_HowItWorksDialogComponent = [i0.styles];
var RenderType_HowItWorksDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HowItWorksDialogComponent, data: {} });
export { RenderType_HowItWorksDialogComponent as RenderType_HowItWorksDialogComponent };
export function View_HowItWorksDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["How it works?"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1. REQUEST A QUOTE"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "how-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Submit a flight request with your trip details and contact information."])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2. REVIEW ITINERARIES"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "how-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your personal travel concierge will quickly send you the best prices and flight options."])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["3. BOOK & SAVE"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "p", [["class", "how-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose your seats, pay securely online, receive e-ticket by email, and save 30%-70%."])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cdnPath, "/assets/img/logo-hiw.png"); _ck(_v, 20, 0, currVal_0); }); }
export function View_HowItWorksDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-how-it-works-dialog", [], null, null, null, View_HowItWorksDialogComponent_0, RenderType_HowItWorksDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.HowItWorksDialogComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HowItWorksDialogComponentNgFactory = i1.ɵccf("app-how-it-works-dialog", i2.HowItWorksDialogComponent, View_HowItWorksDialogComponent_Host_0, {}, {}, []);
export { HowItWorksDialogComponentNgFactory as HowItWorksDialogComponentNgFactory };
