import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-flying-to-dialog',
  templateUrl: './flying-to-dialog.component.html',
  styleUrls: ['./flying-to-dialog.component.scss']
})
export class FlyingToDialogComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  public ngOnInit(): void {
  }

  public closeDialog(): void {
    this.activeModal.close();
  }
}
