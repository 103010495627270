export const CruiseDeparturesList: Array<string> =  [
  'Any',
  'AK - Seward',
  'AK - Whittier/Anchorage',
  'AL - Mobile',
  'CA - LA/Long Beach',
  'CA - LA/San Pedro',
  'CA - San Diego',
  'CA - San Francisco',
  'FL - Ft. Lauderdale',
  'FL - Jacksonville',
  'FL - Miami',
  'FL - Port Canaveral',
  'FL - Tampa',
  'HI - Honolulu/Oahu',
  'LA - New Orleans',
  'MA - Boston',
  'MD - Baltimore',
  'NY - Bayonne/New Jersey',
  'NY - Brooklyn',
  'NY - New York',
  'SC - Charleston',
  'TX - Galveston',
  'VA - Norfolk',
  'WA - Seattle',
  'Argentina - Buenos Aires',
  'Barbados - Bridgetown',
  'Canada - Montreal, Q.C.',
  'Canada - Quebec City, Q.C.',
  'Canada - Vancouver, B.C.',
  'Chile - Valparaiso',
  'D.R. - La Romana',
  'Denmark - Copenhagen',
  'England - Dover',
  'England - London',
  'England - Southampton',
  'France - Paris',
  'Germany - Berlin/Warnemunde',
  'Germany - Munich',
  'Germany - Nuremberg',
  'Greece - Piraeus/Athens',
  'Hong Kong',
  'Hungary - Budapest',
  'Iceland - Reykjavik',
  'Italy - Rome/Civitavecchia',
  'Italy - Venice',
  'Malta - Valletta',
  'Monaco - Monte Carlo',
  'Netherlands - Amsterdam',
  'Norway - Bergen',
  'Portugal - Lisbon',
  'Puerto Rico - San Juan',
  'Spain - Barcelona',
  'St. Maarten - Philipsburg',
  'Sweden - Stockholm',
  'Switzerland - Basel',
  'Tahiti - Papeete',
  'Turkey - Istanbul',
  'U.A.E. - Dubai'
];
