<!-- Header with Background Image -->
<header *ngIf="withForm">
    <div class="container">
        <div class="row">
        <div class="col-md-12 col-lg-6 top-text">
            <h1>Business Class Flights to Europe at 77%* Off</h1>
            <p>from <span>$1,850</span> round-trip</p>
            <div class="airlines-searchbox row">
                <div class="col-md-4">
                    <div><img class="img-fluid" src="./assets/img/airlines-searchbox/Lufthansa_b.png"></div>
                    <div><img class="img-fluid" src="./assets/img/airlines-searchbox/AirFrance_b.png"></div>
                    
                </div>
                <div class="col-md-4">
                    <div><img class="img-fluid" src="./assets/img/airlines-searchbox/British_b.png"></div>
                    <div><img class="img-fluid" src="./assets/img/airlines-searchbox/AmericanAirlines_b.png"></div>
                   
                </div>
                <div class="col-md-4">

                    <div><img class="img-fluid" src="./assets/img/airlines-searchbox/United_b.png"></div>
                    <div><img class="img-fluid" src="./assets/img/airlines-searchbox/Virgin_b.png"></div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-6 block-form">

            <div class="main-form-text">
                <span>GET 5+ FREE QUOTES WITHIN MINUTES</span>
            </div>
            <div class="trip-type col-md-12">
                <a
                        class="tripClick"
                        (click)="tripTypeContent = !tripTypeContent"
                        style="color: #333333;"
                >
          <span class="tripClick" *ngIf="queryFlightParams.tripType == 'Round'"
          >Round-Trip
          </span>
                    <span class="tripClick" *ngIf="queryFlightParams.tripType == 'OneWay'"
                    >One-Way
          </span>
                    <span
                            class="tripClick"
                            *ngIf="queryFlightParams.tripType == 'MultiCity'"
                    >Multi-city
          </span>
                    <i class="tripClick fa fa-angle-down" aria-hidden="true"></i>
                </a>
                <a
                        class="passengersClick"
                        (click)="passengersContent = !passengersContent"
                        style="color: #333333; text-align: center;"
                >
                    {{ queryFlightParams.passengers }}
                    {{ queryFlightParams.passengers > 1 ? "Travelers" : "Traveler" }}
                    <i class="passengersClick fa fa-angle-down" aria-hidden="true"></i>
                </a>
                <a
                        class="cabinClick"
                        (click)="cabinContent = !cabinContent"
                        style="color: #333333; text-align: right;"
                >
                    {{
                    queryFlightParams.cabin == "PREMIUMECO"
                        ? "Premium Economy"
                        : capitalize(queryFlightParams.cabin)
                    }}
                    <span
                            class="cabinClick"
                            *ngIf="queryFlightParams.cabin != 'PREMIUMECO'"
                    >Class
          </span>
                    <i class="cabinClick fa fa-angle-down" aria-hidden="true"></i>
                </a>
            </div>
            <div class="form-bg">
                <form
                        *ngIf="queryFlightParams.tripType == 'Round'"
                        [formGroup]="roundTripForm"
                >
                    <div class="row" id="round-trip-form">
                        <div class="col-6 col-md-6 padding-min">
                            <label>From*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !roundTripForm.get('flyingFrom').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="from-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    appClearIcon
                                    autocomplete="off"
                                    [matAutocomplete]="autoFrom"
                                    formControlName="flyingFrom"
                                    [(ngModel)]="flight.from"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoFrom="matAutocomplete"
                                (optionSelected)="jumpToNextControl(flyingTo)"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredFromData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-6 col-md-6 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>To*</label>
                            <input
                                    id="flyingToRound"
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !roundTripForm.get('flyingTo').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingTo
                                    type="text"
                                    name="to-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [matAutocomplete]="autoTo"
                                    formControlName="flyingTo"
                                    [(ngModel)]="flight.to"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoTo="matAutocomplete"
                                (optionSelected)="jumpToNextControl(dpDepart, 'flyingToRound')"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredToData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-12 col-md-12 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-6 col-md-6 padding-none depart-rt"
                                        (click)="activeDepart = true"
                                        (appClickOutside)="onBlur('roundTripDepart')"
                                >
                                <label>Depart Date</label>
                                    <input
                                            id="roundTripDepart"
                                            type="text"
                                            name="depart-rt"
                                            class="form-control"
                                            placeholder="Select Date"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{'active-datepicker': activeDepart,'has-error':!searchFormValid && !roundTripForm.get('departDate').valid}"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart="ngbDatepicker"
                                            (appClickOutside)="onClickedOutsideDp($event, dpDepart, 'roundTripDepart')"
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart.toggle(); changeMustSubmit(true)"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart"
                                            required
                                            formControlName="departDate"
                                            [readonly]="true"
                                            [(ngModel)]="flight.departDate"
                                            (ngModelChange)="initReturnDatepicker(); jumpToNextControl(dpReturn);"/>
                                </div>
                                <div
                                        class="col-6 col-md-6 padding-none return-rt"
                                        (click)="activeReturn = true"
                                        (appClickOutside)="onBlur('roundTripReturn')"
                                >
                                    <div class="bg-border-gray d-none d-md-block"></div>
                                    <label>Return Date</label>
                                    <input
                                            id="roundTripReturn"
                                            type="text"
                                            name="return-rt"
                                            class="form-control"
                                            placeholder="Select Date"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                      'active-datepicker': activeReturn,
                      'has-error':
                        !searchFormValid &&
                        !roundTripForm.get('returnDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpReturn="ngbDatepicker"
                                            (appClickOutside)="
                      onClickedOutsideDp($event, dpReturn, 'roundTripReturn')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpReturn.toggle(); changeMustSubmit(true)"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateReturn"
                                            [(ngModel)]="returnDate"
                                            required
                                            formControlName="returnDate"
                                            [readonly]="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form
                        *ngIf="queryFlightParams.tripType == 'OneWay'"
                        [formGroup]="oneWayForm"
                >
                    <div class="row" id="one-way-form">
                        <div class="col-6 col-md-4 padding-min">
                            <label>From*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !oneWayForm.get('flyingFrom').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="from-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [(ngModel)]="flight.from"
                                    required
                                    formControlName="flyingFrom"
                                    [matAutocomplete]="autoFrom"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoFrom="matAutocomplete"
                                (optionSelected)="jumpToNextControl(flyingToOneWay)"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredFromOneWayData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-6 col-md-4 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>To*</label>
                            <input
                                    id="flyingToOneWay"
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !oneWayForm.get('flyingTo').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToOneWay
                                    type="text"
                                    name="to-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [(ngModel)]="flight.to"
                                    required
                                    [matAutocomplete]="autoTo"
                                    formControlName="flyingTo"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoTo="matAutocomplete"
                                (optionSelected)="jumpToNextControl(dpDepart, 'flyingToOneWay')"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredToOneWayData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-12 col-md-4 padding-min">
                            <div class="row date-input">
                                <div class="bg-border-gray d-none d-md-block"></div>
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt"
                                        (click)="activeDepart = true"
                                        (appClickOutside)="onBlur('oneWayDepart')"
                                ><label>Depart Date</label>
                                    <input
                                            id="oneWayDepart"
                                            type="text"
                                            name="depart-rt"
                                            class="form-control"
                                            placeholder="Select Date"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                      'active-datepicker': activeDepart,
                      'has-error':
                        !searchFormValid && !oneWayForm.get('departDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart="ngbDatepicker"
                                            (appClickOutside)="
                      onClickedOutsideDp($event, dpDepart, 'oneWayDepart')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart.toggle(); changeMustSubmit(true)"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart"
                                            [(ngModel)]="flight.departDate"
                                            required
                                            formControlName="departDate"
                                            [readonly]="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form
                        *ngIf="queryFlightParams.tripType == 'MultiCity'"
                        [formGroup]="multiCityForm"
                >
                    <div class="row" id="multi-city-form">
                        <div class="col-6 col-md-4 padding-min">
                            <label>From*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !multiCityForm.get('flyingFrom').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="from-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    appClearIcon
                                    autocomplete="off"
                                    [(ngModel)]="flight.from"
                                    required
                                    formControlName="flyingFrom"
                                    [matAutocomplete]="autoFrom"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoFrom="matAutocomplete"
                                (optionSelected)="jumpToNextControl(flyingToMulty)"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredFromMultiCityData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-6 col-md-4 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>To*</label>
                            <input
                                    id="flyingToMultiCity"
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !multiCityForm.get('flyingTo').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToMulty
                                    type="text"
                                    name="to-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [(ngModel)]="flight.to"
                                    required
                                    [matAutocomplete]="autoTo"
                                    formControlName="flyingTo"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoTo="matAutocomplete"
                                (optionSelected)="
                  jumpToNextControl(dpDepart, 'flyingToMultiCity')
                "
                            >
                                <mat-option
                                        *ngFor="let city of (filteredToMultiCityData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-12 col-md-4 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt"
                                        (click)="activeDepart = true"
                                        (appClickOutside)="onBlur('multiCityDepart')"
                                ><label>Depart Date</label>
                                    <input
                                            id="multiCityDepart"
                                            type="text"
                                            name="depart-rt"
                                            class="form-control"
                                            placeholder="Select Date"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                      'active-datepicker': activeDepart,
                      'has-error':
                        !searchFormValid &&
                        !multiCityForm.get('departDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart="ngbDatepicker"
                                            (appClickOutside)="
                      onClickedOutsideDp($event, dpDepart, 'multiCityDepart')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart.toggle(); changeMustSubmit(true)"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart"
                                            formControlName="departDate"
                                            [(ngModel)]="flight.departDate"
                                            [readonly]="true"
                                            (ngModelChange)="
                      initDepartDatepicker();
                      jumpToNextControl(flyingFromMulty1)
                    "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-4 padding-min">
                            <label>From*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !multiCityForm.get('flyingFrom1').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingFromMulty1
                                    type="text"
                                    name="from-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingFrom1"
                                    [(ngModel)]="flight1.from"
                                    [matAutocomplete]="autoFrom1"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoFrom1="matAutocomplete"
                                (optionSelected)="jumpToNextControl(flyingToMulty1)"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredFrom1MultiCityData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-6 col-md-4 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>To*</label>
                            <input
                                    id="flyingTo1MultiCity"
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !multiCityForm.get('flyingTo1').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToMulty1
                                    type="text"
                                    name="to-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingTo1"
                                    [(ngModel)]="flight1.to"
                                    [matAutocomplete]="autoTo1"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoTo1="matAutocomplete"
                                (optionSelected)="
                  jumpToNextControl(dpDepart1, 'flyingTo1MultiCity')
                "
                            >
                                <mat-option
                                        *ngFor="let city of (filteredTo1MultiCityData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-12 col-md-4 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt"
                                        (click)="activeDepart1 = true"
                                        (appClickOutside)="onBlur('multiCityDepart1')"
                                ><label>Depart Date</label>
                                    <input
                                            id="multiCityDepart1"
                                            type="text"
                                            name="depart-rt"
                                            class="form-control"
                                            placeholder="Select Date"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                      'active-datepicker': activeDepart1,
                      'has-error':
                        !searchFormValid &&
                        !multiCityForm.get('departDate1').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart1="ngbDatepicker"
                                            (appClickOutside)="
                      onClickedOutsideDp($event, dpDepart1, 'multiCityDepart1')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart1.toggle(); changeMustSubmit(true)"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart1"
                                            formControlName="departDate1"
                                            [(ngModel)]="flight1.departDate"
                                            [readonly]="true"
                                            (ngModelChange)="
                      initDepartDatepicker();
                      jumpToNextControl(flyingFromMulty2)
                    "
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                                [hidden]="!showThirdFlight"
                                class="col-6 col-md-4 padding-min"
                        ><label>From*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !multiCityForm.get('flyingFrom2').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingFromMulty2
                                    type="text"
                                    name="from-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingFrom2"
                                    [(ngModel)]="flight2.from"
                                    [matAutocomplete]="autoFrom2"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoFrom2="matAutocomplete"
                                (optionSelected)="jumpToNextControl(flyingToMulty2)"
                            >
                                <mat-option
                                        *ngFor="let city of (filteredFrom2MultiCityData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div
                                [hidden]="!showThirdFlight"
                                class="col-6 col-md-4 padding-min"
                        >
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>To*</label>
                            <input
                                    id="flyingTo2MultiCity"
                                    [ngClass]="{
                  'has-error':
                    !searchFormValid && !multiCityForm.get('flyingTo2').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToMulty2
                                    type="text"
                                    name="to-rt"
                                    class="form-control"
                                    placeholder="City or Airport"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingTo2"
                                    [(ngModel)]="flight2.to"
                                    [matAutocomplete]="autoTo2"
                                    (click)="$event.target.select()"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <mat-autocomplete
                                #autoTo2="matAutocomplete"
                                (optionSelected)="
                  jumpToNextControl(dpDepart2, 'flyingTo2MultiCity')
                "
                            >
                                <mat-option
                                        *ngFor="let city of (filteredTo2MultiCityData | async)"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon"></span>
                        </div>
                        <div
                                [hidden]="!showThirdFlight"
                                class="col-12 col-md-4 padding-min"
                        >
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt"
                                        (click)="activeDepart2 = true"
                                        (appClickOutside)="
                    activeDepart2 = false; onBlur('multiCityDepart2')
                  "
                                ><label>Depart Date</label>
                                    <input
                                            id="multiCityDepart2"
                                            type="text"
                                            name="depart-rt"
                                            class="form-control"
                                            placeholder="Select Date"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                      'active-datepicker': activeDepart2,
                      'has-error':
                        !searchFormValid &&
                        !multiCityForm.get('departDate2').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart2="ngbDatepicker"
                                            (appClickOutside)="
                      onClickedOutsideDp($event, dpDepart2, 'multiCityDepart2')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart2.toggle(); changeMustSubmit(true)"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart2"
                                            formControlName="departDate2"
                                            [(ngModel)]="flight2.departDate"
                                            [readonly]="true"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                                *ngIf="showAddFlightButton"
                                class="col-6 col-md-4 padding-none"
                        >
                            <button type="submit" class="btn-add" (click)="addFlight()">
                                Add Flight
                                <span>+</span>
                            </button>
                        </div>
                    </div>
                </form>
                <form [formGroup]="queryPanelForm">
                    <div
                            *ngIf="normalFlight"
                            class="row justify-content-md-center"
                            id="formLine"
                    >
                        <div class="col-md-6 padding-min">
                            <label>Name</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !queryPanelForm.get('firstName').valid && !queryPanelValid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="reqName"
                                    class="form-control"
                                    placeholder="e.g. John Doe"
                                    maxlength="150"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    formControlName="firstName"
                                    [(ngModel)]="contactData.firstName"
                                    (click)="$event.target.select()"
                                    appClearIcon
                            />
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-md-6 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>Email*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !queryPanelForm.get('email').valid && !queryPanelValid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="reqEmail"
                                    class="form-control"
                                    placeholder="someone@example.com"
                                    maxlength="150"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    formControlName="email"
                                    [(ngModel)]="contactData.email"
                                    (click)="$event.target.select()"
                                    appClearIcon
                            />
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-md-12 padding-min">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <label>Phone Number*</label>
                            <input
                                    [ngClass]="{
                  'has-error':
                    !queryPanelForm.get('phone').valid && !queryPanelValid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="tel"
                                    class="form-control"
                                    placeholder="Phone Number"
                                    formControlName="phone"
                                    [(ngModel)]="contactPhoneModel"
                                    #phoneSelect="ngx-phone-select"
                                    ngxPhoneSelect
                                    (click)="$event.target.select()"
                                    [textMask]="{ mask: phoneMask }"
                                    appClearIcon
                            />
                            <span class="xIcon"></span>
                        </div>
                        <div class="col-md-6 padding-min">
                            <button
                                    type="button"
                                    class="btn"
                                    (click)="getQuote()"
                                    [attr.disabled]="formSending ? '' : null"
                            >
                                {{ submitBtnText }}
                            </button>
                        </div>
                        <div class="col-md-6 padding-min">
                                <div class="hiw-modal">
                                        <!-- Button trigger modal -->
                                        <button type="button" class="btn" (click)="openHowItWorksDialog()">
                                            How it works?
                                        </button>
                                    </div>
                        </div>
                        <div class="col-md-12 padding-min">
                            <small class="privacy-form"
                            >*By providing my contact details and clicking on "Get 5+ FREE Quotes" I agree to be contacted for travel information via phone, text messages and email. No purchase necessary. We respect your 
                                <a routerLink="/privacy-policy">privacy.</a>
                            </small>
                        </div>
                        <div class="col-md-12 padding-min form-icons">
                            <div class="row">
                                <div class="col-4">
                                    <img src="./assets/img/icons/001-shield.svg">
                                    <span>100% Safe & Secure</span>
                                </div>
                                <div class="col-4">
                                    <img src="./assets/img/icons/003-manager.svg">
                                    <span>45,000+ Loyal Customers</span>
                                </div>
                                <div class="col-4">
                                    <img src="./assets/img/icons/002-suitcase.svg">
                                    <span>200 Live Experts</span>
                                </div>
                            </div>
                        </div>
                        </div>
                       
                    <div *ngIf="!normalFlight">
                        <div id="errorBox" class="col-xs-12 pn errorBox container">
                            <div class="alert" role="alert">
                              We don’t offer flights to this destination or economy tickets.
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="row call-bottom-info">
                <div class="col-md-12 text-center">
          <span
          >Call now for instant quotes
            <a href="tel:(888) 559-9822">(888) 559-9822</a>
          </span>
                </div>
            </div>

            <div
                    *ngIf="passengersContent"
                    id="passengers"
                    (appClickOutside)="onClickedOutsidePassengers($event)"
            >
                <div class="cabin-tabs row">
                    <!-- <i class="arrow">▲</i> -->
                    <span class="cabin-name">Travelers:</span>
                    <ul class="d-none d-sm-block">
                        <li *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                            <a
                                    name="a1"
                                    [ngClass]="{ active: queryFlightParams.passengers == count }"
                                    (click)="
                  queryFlightParams.passengers = count;
                  changeTravelers();
                  passengersContent = false
                "
                            >
                                <span>{{ count }}</span>
                            </a>
                        </li>
                    </ul>
                    <ul
                            class="d-block d-sm-none"
                            style="text-align: center; margin-left: 15px; margin-right: 15px;"
                    >
                        <li *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                            <a
                                    name="a1"
                                    [ngStyle]="{
                  padding: count === 10 ? '13px 11px' : '13px 15px'
                }"
                                    [ngClass]="{ active: queryFlightParams.passengers == count }"
                                    (click)="
                  queryFlightParams.passengers = count;
                  changeTravelers();
                  passengersContent = false
                "
                            >
                                <span>{{ count }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="row cabin-close">-->
                <!--              <button type="button" class="btn" (click)="passengersContent=false">Close</button>-->
                <!--            </div>-->
            </div>
            <div
                    *ngIf="cabinContent"
                    id="cabin-type"
                    (appClickOutside)="onClickedOutsideCabinType($event)"
            >
                <div class="cabin-tabs row">
                    <!-- <i class="arrow">▲</i> -->
                    <!--              <span class="cabin-name">Select cabin:</span>-->
                    <ul>
                        <li id="BUSINESS">
                            <a
                                    name="a1"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'BUSINESS' }"
                                    (click)="changeCabinType('BUSINESS'); cabinContent = false"
                            >
                                <span>Business</span>
                            </a>
                        </li>
                        <li id="FIRST">
                            <a
                                    name="a2"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'FIRST' }"
                                    (click)="changeCabinType('FIRST'); cabinContent = false"
                            >
                                <span>First</span>
                            </a>
                        </li>
                        <li id="PREMIUMECO">
                            <a
                                    name="a3"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'PREMIUMECO' }"
                                    (click)="changeCabinType('PREMIUMECO'); cabinContent = false"
                            >
                                <span>Premium Economy</span>
                            </a>
                        </li>
                        <li id="ECONOMY">
                            <a
                                    name="a4"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'ECONOMY' }"
                                    (click)="changeCabinType('ECONOMY'); cabinContent = false"
                            >
                                <span>Economy</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="row cabin-close">-->
                <!--              <button type="button" class="btn" (click)="cabinContent=false">Close</button>-->
                <!--            </div>-->
            </div>

            <div
                    *ngIf="tripTypeContent"
                    id="trip-type"
                    (appClickOutside)="onClickedOutsideTripType($event)"
            >
                <div class="cabin-tabs row">
                    <!-- <i class="arrow">▲</i> -->
                    <!--              <span class="cabin-name">Select trip:</span>-->
                    <ul>
                        <li>
                            <a
                                    id="round-trip"
                                    valtype="round"
                                    [ngClass]="{ active: queryFlightParams.tripType == 'Round' }"
                                    (click)="
                  changeTripType('Round', roundTripForm);
                  tripTypeContent = false
                "
                            >
                                <span>Round-Trip</span>
                            </a>
                        </li>
                        <li>
                            <a
                                    id="one-way"
                                    valtype="oneway"
                                    [ngClass]="{ active: queryFlightParams.tripType == 'OneWay' }"
                                    (click)="
                  changeTripType('OneWay', oneWayForm); tripTypeContent = false
                "
                            >
                                <span>One-way</span>
                            </a>
                        </li>
                        <li>
                            <a
                                    id="multi-city"
                                    valtype="multi"
                                    [ngClass]="{
                  active: queryFlightParams.tripType == 'MultiCity'
                }"
                                    (click)="
                  changeTripType('MultiCity', multiCityForm);
                  tripTypeContent = false
                "
                            >
                                <span>Multi-city</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="row cabin-close">-->
                <!--              <button type="button" class="btn" (click)="tripTypeContent=false">Close</button>-->
                <!--            </div>-->
            </div>
        </div>
    </div>
        <div class="justify-content-center">
            <p *ngIf="phoneVisible" class="phone-form-bottom">
                Questions? Call us
                <a href="tel:{{ phone }}">{{ phone | phone }}</a>
            </p>
            <div *ngIf="!phoneVisible" class="hiw-modal">
                <!-- Button trigger modal -->
                <!-- <button type="button" class="btn" (click)="openHowItWorksDialog()">
                    How it works?
                </button> -->
            </div>
        </div>
    </div>
</header>
