import { MonthNamesList } from '../../shared/lists/month-names-list';

export function cruiseDepartDateList(): Array<string> {
  const date: Date = new Date();
  const months: Array<any> = [];
  months.push('Any Date');
  for (let i: number = 0; i < 24; i++) {
    months.push(MonthNamesList[date.getMonth()] + ' ' + date.getFullYear());
    date.setMonth(date.getMonth() + 1);
  }

  return months;
}
