import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { SearchboxService } from '../../templates/searchbox/searchbox.service';

@Component({
  selector: 'app-flying-from-dialog',
  templateUrl: './flying-from-dialog.component.html',
  styleUrls: ['./flying-from-dialog.component.scss'],
  providers: [
    SearchboxService
  ]
})
export class FlyingFromDialogComponent implements OnInit {
  public isFlyingFrom: boolean;
  public filteredList: Observable<Array<string>>;
  public selectedAirport: string;
  public departureDestinationControl: FormControl = new FormControl(null);

  constructor(
    private activeModal: NgbActiveModal,
    private searchboxService: SearchboxService
  ) { }

  public ngOnInit(): void {
    this.filteredList = this.initAutocomplete();
  }

  public initAutocomplete(): any {
    return this.departureDestinationControl.valueChanges
        .pipe(
            debounceTime(200),
            switchMap(value => {
                  if (_.isNil(value.match(/\D+\(\w+\)/i)) && !_.isEmpty(value)) {
                    return this.searchboxService.getLocations(value.trim())
                        .pipe(
                            map((response: any[]) => {
                                  if (response.length === 0 && !_.isNull(value) && !_.isEmpty(value)) {
                                    return Array.of('No cities found');
                                  } else {
                                    return response;
                                  }
                                }
                            ));
                  } else {
                    return [];
                  }
                }
            ));
  }

  public selectAirport(item: string): void {
    this.selectedAirport = item;
    this.closeDialog();
  }

  public closeDialog(): void {
    this.activeModal.close(this.selectedAirport);
  }
}
