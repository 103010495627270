<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-back">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">Your destination airport!</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form>
                    <div class="form-input">
                        <label for="destination">To</label>
                        <input type="text" class="form-control" id="departure" placeholder="Enter a country, city or airport">
                        <span class="xIcon"></span>
                    </div>
                   
                    <span class="list-title">Destination airports</span>
                </form>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6">

                        <form>
                            <div class="list-box">
                                <li>
                                    <div class="airports selected">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>United Kingdom</small>
                                            <span>London [LON]</span>
                                        </div>
                                    </div>
                                </li>
                             
                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>France</small>
                                            <span>Paris [PAR]</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>Italy</small>
                                            <span>Rome [ROM]</span>
                                        </div>
                                    </div>
                                </li>

                              
                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>Japan</small>
                                            <span>Tokyo [NRT]</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>Netherlands</small>
                                            <span>Amsterdam [AMS]</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>Thailand</small>
                                            <span>Bangkok [BKK]</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>Spain</small>
                                            <span>Madrid [MAD]</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>China</small>
                                            <span>Shanghai [PVG]</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="airports">
                                        <i class="icon-plane"></i>
                                        <div class="airport-name">
                                            <small>Belgium</small>
                                            <span>Brussels [BRU]</span>
                                        </div>
                                    </div>
                                </li>
                              
                            </div>
                        </form>
                       
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>