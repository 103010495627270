import {NgModule} from '@angular/core';
import {Routes, RouterModule, UrlSerializer} from '@angular/router';
import {HomeComponent} from './layout/pages/home/home.component';
import {OrderUrlSerializer} from './serializers/OrderUrlSerializer';
import {SearchboxEuropeComponent} from './layout/templates/searchbox-europe/searchbox-europe.component';
import {
  SearchboxNewGenerationComponent
} from "./layout/templates/searchbox-new-generation/searchbox-new-generation.component";
import { OfferTalkingAboutModule } from "../modules/offer-talking-about/offer-talking-about.module";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'first-class', component: HomeComponent},
  {path: 'home', redirectTo: ''},
  {path: 'kayak', loadChildren: () => import('../modules/kayak/kayak.module').then(mod => mod.KayakModule)},
  {path: 'cruise', loadChildren: () => import('../modules/cruise/cruise.module').then(mod => mod.CruiseModule)},
  {path: 'media-alpha', loadChildren: () => import('../modules/kayak/kayak.module').then(mod => mod.KayakModule)},
  {path: 'kayak-banner', loadChildren: () => import('../modules/kayak-banner/kayak-banner.module').then(mod => mod.KayakBannerModule)},
  {path: 'about-us', loadChildren: () => import('../modules/faq/faq.module').then(mod => mod.FaqModule)},
  {path: 'contact-us', loadChildren: () => import('../modules/contact-us/contact-us.module').then(mod => mod.ContactUsModule)},
  {path: 'terms-and-conditions', loadChildren: () => import('../modules/terms-and-conditions/terms-and-conditions.module').then(mod => mod.TermsAndConditionsModule)},
  {path: 'privacy-policy', loadChildren: () => import('../modules/privacy-policy/privacy-policy.module').then(mod => mod.PrivacyPolicyModule)},
  {path: 'corporate', loadChildren: () => import('../modules/corporate/corporate.module').then(mod => mod.CorporateModule)},
  {path: 'corporate-private', loadChildren: () => import('../modules/corporate-private/corporate-private.module').then(mod => mod.CorporatePrivateModule)},
  {path: 'reviews', loadChildren: () => import('../modules/reviews/reviews.module').then(mod => mod.ReviewsModule)},
  {path: 'our-team', loadChildren: () => import('../modules/our-team/our-team.module').then(mod => mod.OurTeamModule)},
  {path: 'business-class/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'business-class-flights-to/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'business-class-flights-to/:deal(s:b)', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'business-class-airlines/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'first-class/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'first-class-flights-to/:deal', loadChildren: () => import('../modules/offer/offer.module').then(mod => mod.OfferModule)},
  {path: 'we-talking-about/:deal', loadChildren: () => import('../modules/offer-talking-about/offer-talking-about.module').then(mod => mod.OfferTalkingAboutModule)},
  {path: 'all-business-class/:deal', loadChildren: () => import('../modules/flights/flights.module').then(mod => mod.FlightsModule)},
  {path: 'your-request', loadChildren: () => import('../modules/your-request/your-request.module').then(mod => mod.YourRequestModule)},
  {path: 'your-request/:type', loadChildren: () => import('../modules/your-request/your-request.module').then(mod => mod.YourRequestModule)},
  {path: 'app-page', loadChildren: () => import('../modules/mobile-app/mobile-app.module').then(mod => mod.MobileAppModule)},
  {path: 'privacy-app', loadChildren: () => import('../modules/privacy-app/privacy-app.module').then(mod => mod.PrivacyAppModule)},
  {path: 'booking-buddy', loadChildren: () => import('../modules/booking-buddy/booking-buddy.module').then(mod => mod.BookingBuddyModule)},
  {path: 'referral-program', loadChildren: () => import('../modules/referral-program/referral-program.module').then(mod => mod.ReferralProgramModule)},
  {path: 'unsubscribe/:customerId', loadChildren: () => import('../modules/unsubscribe/unsubscribe.module').then(mod => mod.UnsubscribeModule)},
  {path: 'unsubscribe/:customerId', loadChildren: () => import('../modules/unsubscribe/unsubscribe.module').then(mod => mod.UnsubscribeModule)},
  {path: 'hotels', loadChildren: () => import('../modules/hotels/hotels.module').then(mod => mod.HotelsModule)},
  // {path: 'market', component: MarketComponent},
  {path: '**', redirectTo: ''},
  {path: 'a', outlet: 's', component: SearchboxEuropeComponent},
  {path: '**', component: SearchboxEuropeComponent, outlet: 's'},
  {path: 'special-cities', outlet: 'k', component: SearchboxNewGenerationComponent},
  {path: '**', outlet: 'k', component: SearchboxNewGenerationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{provide: UrlSerializer, useClass: OrderUrlSerializer}]
})
export class AppRoutingModule { }
