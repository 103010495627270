import {Injectable} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { CallMeDialogComponent } from '../layout/modal/call-me-dialog/call-me-dialog.component';
import { DataService } from '../../shared/shared-data.service';
import { NewCallBackDialogComponent } from "../layout/modal/new-call-back-dialog/new-call-back-dialog.component";

@Injectable()
export class CallMeService {

  constructor(
      private modalService: NgbModal,
      private dataService: DataService
  ) {
  }

  public openCallMeDialog(): void  {
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef: NgbModalRef = this.modalService.open(CallMeDialogComponent, {
      windowClass: 'call-me-dialog',
      backdropClass: 'hiw-backdrop'
    });
    dialogRef.result.then((result) => {
      this.dataService.sendData({type: 'showModal', data: false});
    }, (reason) => {
      this.dataService.sendData({type: 'showModal', data: false});
    });
  }

  public openNewCallBackDialog(data: { nameOfCity: string, price: string }): void  {
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef: NgbModalRef = this.modalService.open(NewCallBackDialogComponent, {
      windowClass: 'call-me-dialog',
      backdropClass: 'hiw-backdrop'
    });
    dialogRef.componentInstance.data = data;
    dialogRef.result.then((result) => {
      this.dataService.sendData({type: 'showModal', data: false});
    }, (reason) => {
      this.dataService.sendData({type: 'showModal', data: false});
    });
  }
}
