import { Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import * as _ from 'lodash';
import { ApplicationEventBroadcaster } from '../../shared/application.event.broadcaster';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../storage/universal.inject";
import * as i3 from "../../shared/application.event.broadcaster";
export class TrackingService {
    constructor(http, appStorage, platformId, injector, broadcaster) {
        this.http = http;
        this.appStorage = appStorage;
        this.platformId = platformId;
        this.injector = injector;
        this.broadcaster = broadcaster;
        this.trackInCookie = false;
        this.startTracking = false;
        this.broadcaster.onType(TrackingStartRequestEvent).subscribe(() => {
            this.startTracking = true;
        });
        this.broadcaster.onType(TrackingFinishRequestEvent).subscribe(() => {
            this.startTracking = false;
            this.clear();
        });
    }
    getTrack() {
        console.debug('Track request...');
        this.trackInCookie = !!this.appStorage.getItem('WF_T');
        if (this.trackInCookie && !this.queryStringParams()) {
            console.debug('Track exist in cookie...');
            return Observable.of(this.appStorage.getItem('WF_T'));
        }
        else {
            if (this._current) {
                console.debug('Current track...');
                return this._current;
            }
            console.debug('New track...');
            return this._current = cacheable(this.http.get(environment.serverUrl + '/track'));
        }
    }
    clear() {
        this._current = null;
    }
    queryStringParams() {
        if (!isPlatformBrowser(this.platformId)) {
            const req = this.injector.get('REQUEST');
            return !_.isEmpty(req.query);
        }
        return !!location.search;
    }
}
TrackingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackingService_Factory() { return new TrackingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppStorage), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i3.ApplicationEventBroadcaster)); }, token: TrackingService, providedIn: "root" });
export function cacheable(o) {
    let replay = new ReplaySubject(1);
    o.subscribe(x => replay.next(x), x => replay.error(x), () => replay.complete());
    return replay.asObservable();
}
export class TrackingEvent {
}
export class TrackingStartRequestEvent {
}
export class TrackingFinishRequestEvent {
}
