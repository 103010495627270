import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../../app/pipes/safe-html-pipe';
import { ServiceComponent } from '../../app/layout/templates/service/service.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { OurServicesComponent } from '../../app/layout/templates/our-services/our-services.component';
import { YelpReviewsComponent } from '../../app/layout/templates/yelp-reviews/yelp-reviews.component';
import { ScriptInlineComponent } from '../../app/layout/templates/script-inline/script-inline.component';
import { PhonePipe } from '../../app/pipes/phone.pipe';
import { TrustpilotBoxComponent } from '../../app/layout/templates/trustpilot-box/trustpilot-box.component';
import { TrustpilotGridComponent } from '../../app/layout/templates/trustpilot-grid/trustpilot-grid.component';
import { NewsletterFormComponent } from '../../app/layout/templates/newsletter-form/newsletter-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MobileAppBlockComponent } from '../../app/layout/templates/mobile-app-block/mobile-app-block.component';
import { RouterModule } from '@angular/router';
import { SearchboxComponent } from '../../app/layout/templates/searchbox/searchbox.component';
import { MatAutocompleteModule } from '@angular/material';
import { NgxPhoneSelectModule } from '../ngx-phone-select/ngx-phone-select.module';
import { TextMaskModule } from 'angular2-text-mask';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { PopularFlightsComponent } from '../../app/layout/templates/popular-flights/popular-flights.component';
import { AirlinesBlockComponent } from '../../app/layout/templates/airlines-block/airlines-block.component';
import { CorporateInfoComponent } from '../../app/layout/templates/corporate-info/corporate-info.component';
import { HowItWorksComponent } from '../../app/layout/templates/how-it-works/how-it-works.component';
import { InfoComponent } from '../../app/layout/templates/info/info.component';
import { ClearIconDirective } from '../../app/directives/clear-icon.directive';
import { ClickOutsideDirective } from '../../app/directives/click-outside.directive';
import { SearchboxEuropeComponent } from '../../app/layout/templates/searchbox-europe/searchbox-europe.component';
import { ServiceLuxaryComponent } from '../../app/layout/templates/service-luxary/service-luxary.component';
import { InfoLuxaryComponent } from '../../app/layout/templates/info-luxary/info-luxary.component';
import {
  OurServicesLuxaryComponent
} from '../../app/layout/templates/our-services-luxary/our-services-luxary.component';
import { SearchboxCruiseComponent } from '../../app/layout/templates/searchbox-cruise/searchbox-cruise.component';
import {
  SearchboxNewGenerationComponent
} from '../../app/layout/templates/searchbox-new-generation/searchbox-new-generation.component';
import { SectionFiveComponent } from '../../app/layout/templates/section-five/section-five.component';
import { SectionFourComponent } from '../../app/layout/templates/section-four/section-four.component';
import { SectionThreeComponent } from '../../app/layout/templates/section-three/section-three.component';
import { SectionTwoComponent } from '../../app/layout/templates/section-two/section-two.component';
import { SectionOneComponent } from '../../app/layout/templates/section-one/section-one.component';
import { SectionSixComponent } from '../../app/layout/templates/section-six/section-six.component';
import { NgxSlickCarouselModule } from '../ngx-slick-carousel/ngx-slick-carousel.module';
import { SearchboxHotelsComponent } from '../../app/layout/templates/searchbox-hotels/searchbox-hotels.component';
import { SectionSevenComponent } from '../../app/layout/templates/section-seven/section-seven.component';
import { CallMeService } from '../../app/services/call-me.service';
import { CallMeHttpService } from '../../app/services/call-me-http.service';
import { FlyingFromDialogComponent } from '../../app/layout/modal/flying-from-dialog/flying-from-dialog.component';
import { FlyingToDialogComponent } from '../../app/layout/modal/flying-to-dialog/flying-to-dialog.component';
import { RoundTripDateDialogComponent } from '../../app/layout/modal/round-trip-date-dialog/round-trip-date-dialog.component';
import { FlightOptionsDialogComponent } from '../../app/layout/modal/flight-options-dialog/flight-options-dialog.component';
import {
  TravelerNameDialogComponent
} from '../../app/layout/modal/traveler-name-dialog/traveler-name-dialog.component';
import {
  TravelerPhoneDialogComponent
} from '../../app/layout/modal/traveler-phone-dialog/traveler-phone-dialog.component';
import {
  TravelerEmailDialogComponent
} from '../../app/layout/modal/traveler-email-dialog/traveler-email-dialog.component';
import { BundleSaveDialogComponent } from '../../app/layout/modal/bundle-save-dialog/bundle-save-dialog.component';
import {
  CruiseFillMobileFieldsComponent
} from '../../app/layout/modal/cruise-fill-mobile-fields/cruise-fill-mobile-fields.component';
import {
  GetQuoteOptionsDialogComponent
} from '../../app/layout/modal/get-quote-options-dialog/get-quote-options-dialog.component';
import { SearchboxFlightsMobileService } from '../../app/services/searchbox-flights-mobile.service';
import { TransformFlightBracketsPipe } from '../../app/pipes/transform-flight-brackets.pipe';
import {
  DepartReturnDateDialogComponent
} from '../../app/layout/modal/depart-return-date-dialog/depart-return-date-dialog.component';
import {
  FlyingCombinedDirectionDialogComponent
} from "../../app/layout/modal/flying-combined-direction-dialog/flying-combined-direction-dialog.component";
import { NavigationRoundsComponent } from "../../app/layout/templates/navigation-rounds/navigation-rounds.component";
import {
  SearchboxTalkingAboutComponent
} from "../../app/layout/templates/searchbox-talking-about/searchbox-talking-about.component";

@NgModule({
  exports: [
    SafeHtmlPipe,
    PhonePipe,
    ServiceComponent,
    OurServicesComponent,
    YelpReviewsComponent,
    ScriptInlineComponent,
    TrustpilotBoxComponent,
    TrustpilotGridComponent,
    NewsletterFormComponent,
    MobileAppBlockComponent,
    PopularFlightsComponent,
    SearchboxComponent,
    SearchboxCruiseComponent,
    AirlinesBlockComponent,
    CorporateInfoComponent,
    HowItWorksComponent,
    InfoComponent,
    ClearIconDirective,
    ClickOutsideDirective,
    SearchboxEuropeComponent,
    OurServicesLuxaryComponent,
    ServiceLuxaryComponent,
    InfoLuxaryComponent,
    SearchboxNewGenerationComponent,
    SectionFiveComponent,
    SectionFourComponent,
    SectionThreeComponent,
    SectionTwoComponent,
    SectionOneComponent,
    SectionSixComponent,
    SearchboxHotelsComponent,
    SectionSevenComponent,
    FlyingFromDialogComponent,
    FlyingToDialogComponent,
    RoundTripDateDialogComponent,
    FlightOptionsDialogComponent,
    TravelerNameDialogComponent,
    TravelerPhoneDialogComponent,
    TravelerEmailDialogComponent,
    BundleSaveDialogComponent,
    CruiseFillMobileFieldsComponent,
    GetQuoteOptionsDialogComponent,
    TransformFlightBracketsPipe,
    DepartReturnDateDialogComponent,
    FlyingCombinedDirectionDialogComponent,
    NavigationRoundsComponent,
    SearchboxTalkingAboutComponent
  ],
  declarations: [
    SafeHtmlPipe,
    PhonePipe,
    ServiceComponent,
    OurServicesComponent,
    YelpReviewsComponent,
    ScriptInlineComponent,
    TrustpilotBoxComponent,
    TrustpilotGridComponent,
    NewsletterFormComponent,
    MobileAppBlockComponent,
    PopularFlightsComponent,
    SearchboxComponent,
    SearchboxCruiseComponent,
    AirlinesBlockComponent,
    CorporateInfoComponent,
    HowItWorksComponent,
    InfoComponent,
    ClearIconDirective,
    ClickOutsideDirective,
    SearchboxEuropeComponent,
    OurServicesLuxaryComponent,
    ServiceLuxaryComponent,
    InfoLuxaryComponent,
    SearchboxNewGenerationComponent,
    SectionFiveComponent,
    SectionFourComponent,
    SectionThreeComponent,
    SectionTwoComponent,
    SectionOneComponent,
    SectionSixComponent,
    SearchboxHotelsComponent,
    SectionSevenComponent,
    FlyingFromDialogComponent,
    FlyingToDialogComponent,
    RoundTripDateDialogComponent,
    FlightOptionsDialogComponent,
    TravelerNameDialogComponent,
    TravelerPhoneDialogComponent,
    TravelerEmailDialogComponent,
    BundleSaveDialogComponent,
    CruiseFillMobileFieldsComponent,
    GetQuoteOptionsDialogComponent,
    TransformFlightBracketsPipe,
    DepartReturnDateDialogComponent,
    FlyingCombinedDirectionDialogComponent,
    NavigationRoundsComponent,
    SearchboxTalkingAboutComponent
  ],
  imports: [
    LazyLoadImageModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatAutocompleteModule,
    NgxPhoneSelectModule,
    TextMaskModule,
    DeferLoadModule,
    NgxSlickCarouselModule
  ],
  providers: [
      CallMeService,
      CallMeHttpService,
      SearchboxFlightsMobileService
  ],
  entryComponents: [
    FlyingFromDialogComponent,
    FlyingToDialogComponent,
    RoundTripDateDialogComponent,
    FlightOptionsDialogComponent,
    TravelerNameDialogComponent,
    TravelerPhoneDialogComponent,
    TravelerEmailDialogComponent,
    BundleSaveDialogComponent,
    CruiseFillMobileFieldsComponent,
    GetQuoteOptionsDialogComponent,
    DepartReturnDateDialogComponent,
    FlyingCombinedDirectionDialogComponent,
    NavigationRoundsComponent
  ]
})
export class GeneralModule {
}
