<!-- Page Content Hiw Block-->
<section id="hiw-bar">
    <div class="container py-3 py-md-0">
      <div class="row">
        <div class="col-12 col-md-4 py-2 py-md-4 hiw-block">
            <img src="assets/img/icons/checkmark_circle.svg" alt="check">
            <span><b>Save 30-70% OFF retail prices</b></span>
        </div>
        <div class="col-12 col-md-4 py-2 py-md-4 hiw-block">
            <img src="assets/img/icons/checkmark_circle.svg" alt="check">
            <span><b>Guaranteed best price available</b></span>
        </div>
        <div class="col-12 col-md-4 py-2 py-md-4 hiw-block">
            <img src="assets/img/icons/checkmark_circle.svg" alt="check">
            <span><b>15 million tickets sold since 2008</b></span>
        </div>
      </div>
      <!-- /.row -->
  
    </div>
    <!-- /.container -->
  </section>
  