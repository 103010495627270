import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-how-it-works-dialog',
  templateUrl: './how-it-works-dialog.component.html',
  styleUrls: ['./how-it-works-dialog.component.scss']
})
export class HowItWorksDialogComponent implements OnInit {
  cdnPath: string;
  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.cdnPath = environment.cdnPath;
  }

  onCloseDialog(): void {

    this.activeModal.close();

  }

}
