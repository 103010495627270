import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {isPlatformBrowser} from '@angular/common';
import * as _ from 'lodash';
import * as express from 'express';
import {ApplicationEventBroadcaster} from '../../shared/application.event.broadcaster';
import {AppStorage} from '../../storage/universal.inject';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  trackInCookie = false;
  _current: Observable<any>;
  startTracking = false;

  constructor(private http: HttpClient,
              @Inject(AppStorage) private appStorage: Storage,
              @Inject(PLATFORM_ID) private platformId: string,
              private injector: Injector,
              private broadcaster: ApplicationEventBroadcaster) {
    this.broadcaster.onType(TrackingStartRequestEvent).subscribe(
      () => {
        this.startTracking = true;
      }
    );
    this.broadcaster.onType(TrackingFinishRequestEvent).subscribe(
      () => {
        this.startTracking = false;
        this.clear();
      }
    );
  }

  getTrack(): Observable<any> {
    console.debug('Track request...');
    this.trackInCookie = !!this.appStorage.getItem('WF_T');
    if (this.trackInCookie && !this.queryStringParams()) {
      console.debug('Track exist in cookie...');
      return Observable.of(this.appStorage.getItem('WF_T'));
    } else {
      if (this._current) {
        console.debug('Current track...');
        return this._current;
      }
      console.debug('New track...');
      return this._current = cacheable<any>(this.http.get<any>(environment.serverUrl + '/track'));
    }
  }

  private clear(): void {
    this._current = null;
  }

  private queryStringParams(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      const req: express.Request = this.injector.get('REQUEST');
      return !_.isEmpty(req.query);
    }
    return !!location.search;
  }
}

export function cacheable<T>(o: Observable<T>): Observable<T> {
  let replay = new ReplaySubject<T>(1);
  o.subscribe(
    x => replay.next(x),
    x => replay.error(x),
    () => replay.complete()
  );
  return replay.asObservable();
}

export class TrackingEvent {
}

export class TrackingStartRequestEvent {
}

export class TrackingFinishRequestEvent {
}

