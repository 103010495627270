import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const gtag = (<any> window).gtag;
    const bing = (<any> window).bing;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        /* Google Analytics pageview event */
        gtag('config', 'G-XHKHFSPZCF', { page_path: event.urlAfterRedirects });
        /* Google AdWords */
        gtag('config', 'AW-979006729');
        console.debug('Visiting ' + event.urlAfterRedirects + ' should be tracked via GTag');
        /* Bing Ads */
        bing();
      }
    });
  }
}
