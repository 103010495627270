import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ContactData } from '../model/contact-data';
import { RestResponse } from '../model/rest-response';
import { environment } from '../../environments/environment';

@Injectable()
export class CallMeHttpService {

  constructor(
      private http: HttpClient
  ) { }

  public callMe(contactData: ContactData): Observable<RestResponse> {
    return this.http.post<RestResponse>(environment.serverUrl + '/contact-info/callback', contactData);
  }
}
