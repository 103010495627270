import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RestResponse} from '../../../model/rest-response';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class OfferService {

  constructor(private http: HttpClient) { }

  getChildren(id): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/custom-page/get-children', {params: {'id': id}});
  }

  getByName(name: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/custom-page/get-by-name', {params: {'pageName': name, 'site': 'bcg'}});
  }

  getParentNames(name: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/custom-page/get-parents', {params: {'pageName': name, 'site': 'bcg'}});
  }

}
