import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {isPlatformBrowser} from '@angular/common';
import * as _ from 'lodash';
import {GuruInsiderSpecialsService} from './guru-insider-specials.service';
import {NotifyService} from '../../../services/notify.service';

@Component({
  selector: 'app-before-close-dialog',
  templateUrl: './before-close-dialog.component.html',
  styleUrls: ['./before-close-dialog.component.scss'],
  providers: [GuruInsiderSpecialsService]
})
export class BeforeCloseDialogComponent implements OnInit {
  cdnPath: string;
  email: string;
  guruInsiderSpecialsForm: FormGroup;
  formValid: boolean;
  formSent: boolean;
  formSending = false;
  alreadySignedUp: boolean;
  staticAlertClosed = false;

  constructor(private activeModal: NgbActiveModal,
              private signUpService: GuruInsiderSpecialsService,
              private cookieService: CookieService,
              private formBuilder: FormBuilder,
              private notifyService: NotifyService,
              @Inject(PLATFORM_ID) private platformId: Object) {
    this.cdnPath = environment.cdnPath;
  }

  ngOnInit() {
    this.formValid = true;
    this.formSent = false;
    this.alreadySignedUp = false;
    this.guruInsiderSpecialsForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isEmpty(this.cookieService.get('WF_GURU_INSIDER_SENT'))) {
        this.alreadySignedUp = true;
      }
      setTimeout(() => this.staticAlertClosed = true, 20000);
    }
  }

  public doNotSendEmail() {
    this.cookieService.set('WF_GURU_INSIDER_SENT', 'don\'t want');
    this.close();
  }

  public sendGuruInsiderSpecials() {
    this.formValid = this.guruInsiderSpecialsForm.valid;
    if (this.formValid) {
      this.formSending = true;

      this.signUpService.sendEmail(this.email).subscribe(
          response => {
            this.formSending = false;
            if (!_.isNil(response.data.error)) {
              this.notifyService.error(response.data.error);
            } else {
              this.cookieService.set('WF_GURU_INSIDER_SENT', this.email);
              this.formSent = true;
              this.close();
            }
          },
          error => {
            this.notifyService.error('Can\'t process your request now. Please try later.');
            this.formSending = false;
          });
    }
  }

  close(): void {
    this.activeModal.close();
  }

  private isEmpty(fieldValue): boolean {
    return _.isEmpty(fieldValue) || _.isNil(fieldValue);
  }
}
