/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-us-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./contact-us-dialog.component";
import * as i4 from "@angular/material/dialog";
var styles_ContactUsDialogComponent = [i0.styles];
var RenderType_ContactUsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactUsDialogComponent, data: {} });
export { RenderType_ContactUsDialogComponent as RenderType_ContactUsDialogComponent };
function View_ContactUsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cdnPath, "/assets/img/icons/ok-mark.svg"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ContactUsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "contact-us-modal  animated fadeIn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["aria-hidden", "true"], ["class", " modal show"], ["role", "dialog"], ["style", "display: block;"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We love feedback!"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "contact-thank-text"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "contact-failed": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactUsDialogComponent_1)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "h3", [["class", "contact-us-response"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "contact-thank-text"; var currVal_1 = _ck(_v, 13, 0, !_co.success); _ck(_v, 12, 0, currVal_0, currVal_1); var currVal_2 = _co.success; _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.text; _ck(_v, 17, 0, currVal_3); }); }
export function View_ContactUsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-us-dialog", [], null, null, null, View_ContactUsDialogComponent_0, RenderType_ContactUsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContactUsDialogComponent, [i4.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactUsDialogComponentNgFactory = i1.ɵccf("app-contact-us-dialog", i3.ContactUsDialogComponent, View_ContactUsDialogComponent_Host_0, {}, {}, []);
export { ContactUsDialogComponentNgFactory as ContactUsDialogComponentNgFactory };
