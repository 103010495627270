<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-back" (click)="moveBack()">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">Cruise options</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeAll()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <span class="list-title">Travelers</span>
                <ul>
                    <li *ngFor="let travelerItem of travelersRoomsList"
                        (click)="selectedTravelers = travelerItem"
                        [class.active]="selectedTravelers === travelerItem">
                        <a><span>{{travelerItem}}</span></a>
                    </li>
                </ul>

                <span class="list-title">Rooms</span>
                <ul>
                    <li *ngFor="let roomItem of travelersRoomsList"
                        (click)="selectedRooms = roomItem"
                        [class.active]="selectedRooms === roomItem">
                        <a id="BUSINESS">
                            <span>{{roomItem}}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="modal-footer">
                <button type="submit" class="btn" (click)="saveCloseDialog()">Save</button>
            </div>

        </div>
    </div>
</div>
