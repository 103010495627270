import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  CruiseFieldsData,
  CruiseFieldsDataListInterface, CruiseListFieldsEnum,
} from '../../../model/cruise-list-fields.enum';
import { SearchboxCruiseMobileService } from '../../../services/searchbox-cruise-mobile.service';
import { CruiseQueryDataInterface } from '../../../model/cruise-query-data.interface';

@Component({
  selector: 'app-cruise-fill-mobile-fields',
  templateUrl: './cruise-fill-mobile-fields.component.html',
  styleUrls: ['./cruise-fill-mobile-fields.component.scss']
})
export class CruiseFillMobileFieldsComponent implements OnInit {
  public readonly cruiseFieldsData: CruiseFieldsDataListInterface = CruiseFieldsData;
  public readonly cruiseQuoteData: CruiseQueryDataInterface;
  public readonly cruiseListFieldsEnum = CruiseListFieldsEnum;
  public listType: string;

  constructor(
      private activeModal: NgbActiveModal,
      private cruiseMobileService: SearchboxCruiseMobileService,
      private modalService: NgbModal
  ) {
    this.cruiseQuoteData = this.cruiseMobileService.getCruiseQuoteData();
  }

  public ngOnInit(): void {
  }

  public closeDialog(data: string = null): void {
    this.activeModal.close(data);
  }

  public selectListItem(item: string): void {
    this.cruiseMobileService.setCruiseDataFromLists(this.listType, item);
    this.closeDialog(item);
  }
}
