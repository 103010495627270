<!-- Modal Call Me-->
<div class="call-me-modal">
  <div id="callMeModal" class="modal fade show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-xl"  role="document">
      
          <div class="modal-content">
            <div class="modal-body">

              <button type="button" class="close" (click)="onCloseDialog()">
                <span aria-hidden="true">&times;</span>
              </button>

              <div class="row align-items-center no-gutters">

                <div class="col-sm-12 col-lg-6 px-4 py-4 py-lg-2 py-xl-4">
                  <h1 class="modal-title">
                    Business Class Flights to {{data.nameOfCity}} from <span>{{data.price}} r/t</span>
                  </h1>
                  <h2 class="modal-title">
                    Get a Free Qoute
                  </h2>
                  <p>A travel expert will send you with a quote shorty.</p>
                  <form id="callMeForm" name="callMeForm" [formGroup]="callMeForm" #callForm="ngForm">
                  <input type="name" [ngClass]="{'has-error': callMeForm.get('firstName').invalid && callForm.submitted}"
                        class="form-control" placeholder="Your Name" formControlName="firstName" [(ngModel)]="callMeData.firstName">
                  <input type="phone" [ngClass]="{'has-error': callMeForm.get('phone').invalid && callForm.submitted}"
                        class="form-control" id="" placeholder="Phone Number" formControlName="phone" [(ngModel)]="callMeData.phone" [textMask]="{mask: phoneMask}">
                    <input type="email" [ngClass]="{'has-error': callMeForm.get('email').invalid && callForm.submitted}"
                          class="form-control" placeholder="Email" formControlName="email" [(ngModel)]="callMeData.email">
                  <button type="submit" class="btn" (click)="callMe()">Get a Free Quote</button>
                  </form>
                  <div class="safe">
                    <span>I agree to provide my phone number and to be contacted by clicking “Get a Free Quote”. Your <a routerlink="/privacy-policy" href="/privacy-policy">privacy</a> is respected. <a routerlink="/terms-and-conditions" href="/terms-and-conditions">Terms of Service</a>.</span>
                    <span class="check"><img src="assets/img/icons/check-y.svg" alt="check"><b> 100% Safe. No spam. Ever.</b></span>
                  </div>
                </div>

                <div class="d-none d-lg-block col-lg-6 overflow-hidden">
                  <div class="row benefits">
                    <div class="col-4 col-lg-4 text-center px-2">
                      <img class="bi" src="assets/img/icons/hiw/Accredited.svg" alt="Accredited business">
                      <p>Accredited business</p>
                    </div>
                    <div class="col-4 col-lg-4 text-center px-2">
                     <img class="bi" src="assets/img/icons/hiw/Trustpilot.svg" alt="Trustpilot top rated">
                      <p>Trustpilot top rated</p>
                    </div>
                    <div class="col-4 col-lg-4 text-center px-2">
                      <img class="bi" src="assets/img/icons/hiw/Live.svg" alt="Live concierge">
                      <p>Live concierge</p>
                    </div>
                  </div>
                  <img class="benefits-img-bg" src="assets/img/modal-call.jpg" alt="Call">
                </div>

                <div class="modal-footer d-none d-md-block">
                  <div class="row">
                    <div class="col hiw-info">
                      <span>1</span>
                      <p><b>Get a free quote</b>Complete the form and request a quote, it only takes few seconds.</p>
                    </div>
                    <div class="col hiw-info">
                      <span>2</span>
                      <p><b>Review itineraries</b>A friendly travel expert will quickly find the lowest price itineraries for you.</p>
                    </div>
                    <div class="col hiw-info">
                      <span>3</span>
                      <p><b>Book and save</b>Select your favorite itinerary, book securely online, and save big!</p>
                    </div>
                  </div>
                </div>

                <div class="d-md-none overflow-hidden">
                  <div class="row benefits benefits-mobile">
                    <div class="col-4 col-lg-4 text-center px-2">
                      <img class="bi" src="assets/img/icons/hiw/Accredited.svg" alt="Accredited business">
                      <p>Accredited business</p>
                    </div>
                    <div class="col-4 col-lg-4 text-center px-2">
                      <img class="bi" src="assets/img/icons/hiw/Trustpilot.svg" alt="Trustpilot top rated">
                      <p>Trustpilot top rated</p>
                    </div>
                    <div class="col-4 col-lg-4 text-center px-2">
                      <img class="bi" src="assets/img/icons/hiw/Live.svg" alt="Live concierge">
                      <p>Live concierge</p>
                    </div>
                  </div>
                  <img class="benefits-img-bg" src="assets/img/modal-call.jpg" alt="Call">
                </div>
                
              </div>
            </div>
          </div>
       
    </div>
  </div>
</div>
