import { Injectable } from '@angular/core';

import { QueryCruiseParams } from '../model/query-cruise-params';
import { ContactDataCruiseInterface } from '../model/contact-data-cruise.interface';
import { CruiseQueryDataInterface } from '../model/cruise-query-data.interface';

@Injectable()

export class SearchboxCruiseMobileService {
  private cruiseQuoteData: CruiseQueryDataInterface = {
    queryCruiseParams: {
      rooms: 1,
      travelers: 2,
      destination: null,
      departDate: null,
      line: null,
      departurePort: null,
      addFlight: false,
      addHotel: false,
      anyDate: false
    },
    contactData: {
      firstName: null,
      email: null,
      phone: null,
      cCode: null
    }
  };

  constructor() {
  }

  public getCruiseQuoteData(): CruiseQueryDataInterface {
    return this.cruiseQuoteData;
  }

  public setRooms(roomsNumber: number): void {
    this.cruiseQuoteData.queryCruiseParams.rooms = roomsNumber;
  }

  public setTravelers(travelersNumber: number): void {
    this.cruiseQuoteData.queryCruiseParams.travelers = travelersNumber;
  }

  public setCruiseDataFromLists(field: string, data: string): void {
    this.cruiseQuoteData.queryCruiseParams[field] = data;
  }

  public setFlight(addedFlight: boolean): void {
    this.cruiseQuoteData.queryCruiseParams.addFlight = addedFlight;
  }

  public setHotel(addedHotel: boolean): void {
    this.cruiseQuoteData.queryCruiseParams.addHotel = addedHotel;
  }

  public deleteCruise(): void {
    this.cruiseQuoteData.queryCruiseParams.departurePort = null;
    this.cruiseQuoteData.queryCruiseParams.destination = null;
    this.cruiseQuoteData.queryCruiseParams.departDate = null;
    this.cruiseQuoteData.queryCruiseParams.line = null;
  }

  public setContactData(contactData: ContactDataCruiseInterface): void {
    this.cruiseQuoteData.contactData = contactData;
  }
}
