<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-back">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">Flight #2</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form>
                    <span class="list-title">Add another flight?</span>
                    <div class="buttons">
                        <button type="submit" class="btn mr-1">Yes</button>
                        <button type="submit" class="btn secondary ml-1">No</button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>