import { Component, OnInit } from '@angular/core';
import {ComponentBase} from '../../common/ComponentBase';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ComponentBase implements OnInit {


  constructor( public router: Router) {  super(); }

  ngOnInit() {
  }

  get showDefaultForm(): boolean {
    return (!this.router.url.includes('europe') || !this.router.url.includes('(s:'));
  }
}
