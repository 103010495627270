import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxSlickCarouselDirective} from './ngx-slick-carousel.directive';



@NgModule({
  declarations: [NgxSlickCarouselDirective],
  imports: [
    CommonModule
  ],
  providers: [NgxSlickCarouselDirective],
  exports: [NgxSlickCarouselDirective]
})
export class NgxSlickCarouselModule { }
