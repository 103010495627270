<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="btn-back" (click)="closeDialog()">
                    <i class="icon-back"></i>
                </button>
                <h2 class="modal-title">{{cruiseFieldsData[listType].text}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6">

                        <form>
                            <div class="list-box">
                                <ng-container *ngIf="listType !== cruiseListFieldsEnum.CruiseLine; else cruiseLineList">
                                    <li *ngFor="let itemList of cruiseFieldsData[listType].list"
                                        (click)="selectListItem(itemList)">
                                        <div class="options"
                                             [class.selected]="itemList === cruiseQuoteData.queryCruiseParams[listType]">
                                            <div class="select-name">
                                                <span>{{itemList}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                                <ng-template #cruiseLineList>
                                    <li *ngFor="let itemList of cruiseFieldsData[listType].list"
                                        (click)="selectListItem(itemList.value)">
                                        <div class="options"
                                             [class.selected]="itemList.value === cruiseQuoteData.queryCruiseParams[listType]">
                                            <div class="select-name">
                                                <span>{{itemList.value}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ng-template>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
