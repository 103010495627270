import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NewsletterFormService} from './newsletter-form.service';
import {CookieService} from 'ngx-cookie-service';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss'],
  providers: [NewsletterFormService]
})
export class NewsletterFormComponent implements OnInit {
  email: string;
  hotDealsForm: FormGroup;
  formValid: boolean;
  formSent: boolean;
  formSending = false;
  alreadySignedUp: boolean;
  private _success = new Subject<string>();

  staticAlertClosed = false;
  successMessage: string;

  constructor(private signUpService: NewsletterFormService,
              private cookieService: CookieService,
              private formBuilder: FormBuilder,
              @Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    this.formValid = true;
    this.formSent = false;
    this.alreadySignedUp = false;
    this.hotDealsForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isEmpty(this.cookieService.get('WF_HOT_DEALS_SENT'))) {
        this.alreadySignedUp = true;
      }
      setTimeout(() => this.staticAlertClosed = true, 20000);
    }

    this._success.subscribe((message) => this.successMessage = message);
    this._success.pipe(debounceTime(5000)).subscribe(() => this.successMessage = null);
  }

  public sendHotDeal() {
    this.formValid = this.hotDealsForm.valid;
    if (this.formValid) {
      this.formSending = true;

      this.signUpService.sendEmail(this.email).subscribe(
        response => {
          this.formSending = false;
          if (!_.isNil(response.data.error)) {
            this.alertMessage(response.data.error);
          } else {
            this.cookieService.set('WF_HOT_DEALS_SENT', this.email);
            this.formSent = true;

            setTimeout(() => {
              this.alreadySignedUp =  true;
            }, 3000);

          }
        },
        error => {
          this.alertMessage('Can\'t process your request now. Please try later.');
          this.formSending = false;
        });
    }
  }

  private isEmpty(fieldValue): boolean {
    return _.isEmpty(fieldValue) || _.isUndefined(fieldValue) || _.isNull(fieldValue);
  }

  private alertMessage(message) {
    this._success.next(message);
  }
}
