import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import * as _ from 'lodash';
import {isPlatformBrowser} from '@angular/common';
import {ComponentBase} from '../ComponentBase';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends ComponentBase implements OnInit {

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    super();
  }

  fixBottom = false;
  private firstClassLandingDetected: boolean;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          this.fixBottom = _.eq(event.url, '/your-request');
        });
    }
  }

  get isBusinessLand(): boolean {
    const businessLanding = this.landingClass === 'First' ? false : true;
    if (!this.firstClassLandingDetected) {
      this.firstClassLandingDetected = !businessLanding;
    }
    return businessLanding && !this.firstClassLandingDetected;
  }

  get landingClass() {
    return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
  }

  get year(): string {
    return moment().format('YYYY'); // current year
  }
}
