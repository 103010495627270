<div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h2 *ngIf="isFlyingFrom" class="modal-title">Your departure airport!</h2>
                <h2 *ngIf="!isFlyingFrom" class="modal-title">Your destination airport!</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form>
                    <div *ngIf="false" class="form-input info-input mb-2">
                        <fieldset disabled>
                            <label for="flightOptions">Flight options</label>
                            <input type="text" class="form-control" id="flightOptions"
                                   placeholder="Round-Trip | 1 Traveler | Business Class">
                            <i class="icon-down"></i>
                        </fieldset>
                    </div>

                    <div class="form-input">
                        <label for="departure">{{ isFlyingFrom ? 'From' : 'To'}}</label>
                        <input [formControl]="departureDestinationControl"
                               type="text"
                               class="form-control"
                               id="departure"
                               placeholder="Enter a country, city or airport"
                               appClearIcon>
                        <span class="xIcon"></span>
                    </div>

                    <span *ngIf="isFlyingFrom" class="list-title">Popular departure airports</span>
                    <span *ngIf="!isFlyingFrom" class="list-title">Popular destination airports</span>
                </form>
            </div>

            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6">
                        <form>
                            <div class="list-box">
                                <ng-container *ngIf="filteredList | async as data">
                                    <ng-container *ngIf="data.length && departureDestinationControl.value">
                                        <li *ngFor="let item of data | transformFlightBrackets"
                                            (click)="selectAirport(item)">
                                            <div class="airports" [class.selected]="selectedAirport === item">
                                                <i class="icon-plane"></i>
                                                <div class="airport-name">
                                                    <span>{{item}}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!departureDestinationControl.value">
                                    <li (click)="selectAirport('New York [JFK]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'New York [JFK]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>New York [JFK]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Los Angeles [LAX]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Los Angeles [LAX]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Los Angeles [LAX]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Chicago [ORD]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Chicago [ORD]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Chicago [ORD]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Washington [IAD]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Washington [IAD]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Washington [IAD]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Miami [MIA]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Miami [MIA]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Miami [MIA]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Dallas [DFW]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Dallas [DFW]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Dallas [DFW]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Houston [IAH]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Houston [IAH]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Houston [IAH]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Boston [BOS]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Boston [BOS]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Boston [BOS]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Orlando [MCO]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Orlando [MCO]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Orlando [MCO]</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li (click)="selectAirport('Atlanta [ATL]')">
                                        <div class="airports"
                                             [class.selected]="selectedAirport === 'Atlanta [ATL]'">
                                            <i class="icon-plane"></i>
                                            <div class="airport-name">
                                                <span>Atlanta [ATL]</span>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
