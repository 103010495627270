import {Injectable, Type} from '@angular/core';
import {Observable, Subject} from 'rxjs/Rx';


@Injectable({
  providedIn: 'root'
})
export class ApplicationEventBroadcaster extends Subject<any> {

  onType<T>(messageClass: Type<T>): Observable<T> {
    return this.asObservable().filter(event => event instanceof messageClass);
  }

}
