<!--<app-attention class="main-block"></app-attention>-->
<!--<app-trustpilot-box></app-trustpilot-box>-->
<app-header (mouseleave)="onMouseLeave($event)"></app-header>
<div id="main-content" class="main-block" >
  <router-outlet *ngIf="withOutletForm" name="s"></router-outlet>
  <router-outlet *ngIf="isSpecialCityOutlet" name="k"></router-outlet>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<div id="spinner" [hidden]="!showSpinner">
  <picture class="animated fadeIn">
    <source [attr.lazyLoad]="cdnPath + '/assets/img/loader.webp'" type="image/webp">
    <img [lazyLoad]="cdnPath + '/assets/img/loader.gif'"/>
  </picture>
</div>
