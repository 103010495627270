import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {CallMeDialogComponent} from '../../modal/call-me-dialog/call-me-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {AppStorage} from '../../../../storage/universal.inject';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {HeaderService} from './header.service';
import {NavigationStart, Router} from '@angular/router';
import {DataService} from '../../../../shared/shared-data.service';
import {interval} from 'rxjs';
import {cookieEnabled} from '../../../../shared/util/cookie-util';
import {TrackingService} from '../../../services/tracking.service';
import {ComponentBase} from '../ComponentBase';
import {filter, takeWhile} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

const PHONE_KEY = makeStateKey('phone');
const PHONE_VISIBLE_KEY = makeStateKey('phoneVisible');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [HeaderService]
})
export class HeaderComponent extends ComponentBase implements OnInit {
  cdnPath: string;
  @ViewChild('navMenuCheckbox', { static: false }) private navMenuCheckbox: ElementRef;

  constructor(public dialog: MatDialog,
              @Inject(AppStorage) private appStorage: Storage,
              private http: HttpClient,
              private state: TransferState,
              private headerService: HeaderService,
              private router: Router,
              private dataService: DataService,
              private trackingService: TrackingService,
              private modalService: NgbModal) {
    super();
  }

  phone: string;
  track: any;
  night: any;
  phoneVisible: boolean;
  homePage: boolean;
  trackId: string;
  private firstClassLandingDetected: boolean;

  ngOnInit() {
    this.cdnPath = environment.cdnPath;
    this.trackingService.getTrack().subscribe(data => {
      this.setPhone();
      this.checkTrackCookie();
    });
    this.showCallMeButton();
  }

  private checkTrackCookie() {
    interval(500).pipe(takeWhile(() => {
      this.trackId = this.appStorage.getItem('WF_T');
      return !this.trackId && cookieEnabled();
    }))
        .subscribe(
            () => console.debug('TrackId = ' + this.trackId),
            () => {},
            () => this.dataService.sendData({type: 'track', data: true})
        );
  }

  setPhoneVisible(): void {
    this.headerService.isNight().subscribe(
        response => {
          this.phoneVisible = !response.data.isNight;
          this.sendPhoneVisibleData();
          this.state.set(PHONE_VISIBLE_KEY, this.phoneVisible as any);
        }
    );
  }

  openCallMeDialog(): void  {
    this.dataService.sendData({type: 'showModal', data: true});
    const dialogRef = this.modalService.open(CallMeDialogComponent, {
      windowClass: 'call-me-dialog',
      backdropClass: 'hiw-backdrop'
    });
    dialogRef.componentInstance.data = {phone: this.phone, phoneVisible: this.phoneVisible, isLaunchedFromHeader: true};
    dialogRef.result.then((result) => {
      this.dataService.sendData({type: 'showModal', data: false});
    }, (reason) => {
      this.dataService.sendData({type: 'showModal', data: false});
    });
  }

  hideMenu(): void {
    this.navMenuCheckbox.nativeElement.checked = false;
    this.dataService.sendData({type: 'showModal', data: false});
  }

  showMenu(): void {
    if (this.navMenuCheckbox.nativeElement.checked === false) {
      this.dataService.sendData({type: 'showModal', data: true});
    } else {
      this.dataService.sendData({type: 'showModal', data: false});
    }
  }

  private setPhone() {
    const phoneFromStorage = this.appStorage.getItem('WF_PHONE');
    if (phoneFromStorage) {
      this.phone = phoneFromStorage;
      this.sendPhoneData();
      this.state.set(PHONE_KEY, this.phone as any);
      this.setPhoneVisible();
    } else {
      this.headerService.getPhone().subscribe(
          response => {
            if (!_.isNil(response.data.phone)) {
              this.phone = response.data.phone;
              this.sendPhoneData();
              this.state.set(PHONE_KEY, this.phone as any);
              this.setPhoneVisible();
            }
          });
    }
  }

  sendPhoneData() {
    this.dataService.sendData({type: 'phone', data: this.phone});
  }

  sendPhoneVisibleData() {
    this.dataService.sendData({type: 'phoneVisible', data: this.phoneVisible});
  }

  private showCallMeButton() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.homePage = _.eq(event.url, '/home') || _.eq(event.url, '/');
      });
  }

  get isBusinessLand(): boolean {
    const businessLanding = this.landingClass === 'First' ? false : true;
    if (!this.firstClassLandingDetected) {
      this.firstClassLandingDetected = !businessLanding;
    }
    return businessLanding && !this.firstClassLandingDetected;
  }

  get landingClass() {
    return _.isNil(this.router) || _.isNil(this.router.url) || !this.router.url.includes('first-class') ? 'Business' : 'First';
  }
}
