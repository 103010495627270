<!-- Page Content Service Block-->
<section id="service">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 service-text">
        <h2 *ngIf="!special" class="mt-4">Luxury fares from travel experts.</h2>
        <h4 *ngIf="special" class="mt-4">Luxury fares from travel experts.</h4>
        <p>We combine technology and human expertise to provide superior service and low fares.</p>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-4 padding-none">
        <div class="service-block">
          <img class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/w1.svg'" alt="">
          <div class="service-block-body">
            <h4 *ngIf="!special" >UNBEATABLE PRICES</h4>
            <h5 *ngIf="special">UNBEATABLE PRICES</h5>
            <p>Deep discounts of up to 70% on first and business class airfare.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-4 padding-none">
        <div class="service-block">
          <img class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/w2.svg'" alt="">
          <div class="service-block-body">
            <h4  *ngIf="!special">PREMIUM SERVICE</h4>
            <h5  *ngIf="special">PREMIUM SERVICE</h5>
            <p>Dedicated travel guru before, during, and after your trip.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-4 padding-none">
        <div class="service-block">
          <img class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/w3.svg'" alt="">
          <div class="service-block-body">
            <h4 *ngIf="!special">SPECIAL FARES</h4>
            <h5 *ngIf="special">SPECIAL FARES</h5>
            <p>We have access to private fares that are not available through online booking websites.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-4 padding-none">
        <div class="service-block">
          <img class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/w4.svg'" alt="">
          <div class="service-block-body">
            <h4 *ngIf="!special">BEST ITINERARIES</h4>
            <h5 *ngIf="special">BEST ITINERARIES</h5>
            <p>We find the best deals through booking strategies, industry contacts and our proprietary deals.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-4 padding-none">
        <div class="service-block">
          <img class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/w5.svg'" alt="">
          <div class="service-block-body">
            <h4 *ngIf="!special">GENUINE SKILLS</h4>
            <h5 *ngIf="special">GENUINE SKILLS</h5>
            <p>Our gurus have extensive knowledge of flight routing, loyalty programs and airfare pricing.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 my-4 padding-none">
        <div class="service-block">
          <img class="service-block-icon" [lazyLoad]="cdnPath + '/assets/img/icons/w6.svg'" alt="">
          <div class="service-block-body">
            <h4 *ngIf="!special">TRUST AND CREDIBILITY</h4>
            <h5 *ngIf="special">TRUST AND CREDIBILITY</h5>
            <p>In business since 2006 with excellent BBB, Google reviews and Yelp ratings.</p>
          </div>
        </div>
      </div>

    </div>
    <!-- /.row -->

  </div>
  <!-- /.container -->
</section>
