import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RestResponse} from '../../../model/rest-response';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PopularFlightsService {

  constructor(private http: HttpClient) { }

  getPopular(type: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/custom-page/get-by-type-and-popular', { params: { 'type': type, 'popular': 'true', 'site': 'bcg' } });
  }
}
