import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string, args?: any): string {
    let result = '';
    const currentPhone = _.replace(value, /-/g, '');
    if (currentPhone !== undefined && currentPhone !== '') {
      result += '(' + currentPhone.slice(0, 3) + ') ' + currentPhone.slice(3, 6) + '-' + currentPhone.slice(6, 10);
    }
    return result;
  }
}
