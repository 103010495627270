<!-- Page Content Newsletter-->
<section id="newsletter" *ngIf="alreadySignedUp == false">
  <div class="container">
    <ngb-alert
      *ngIf="successMessage"
      type="danger"
      (close)="successMessage = null"
      >{{ successMessage }}</ngb-alert
    >
    <div class="row" *ngIf="formSent == false">
      <div class="col-md-12 col-lg-6">
        <h4>Guru's Insider Specials!</h4>
        <span
          >Get the first look at incoming flight deals, specials &
          promotions.</span
        >
      </div>
      <form [formGroup]="hotDealsForm" class="form-inline col-md-12 col-lg-6">
        <div class="col-12 col-sm-8">
          <input
            type="email"
            class="form-control"
            id="inputEmail4"
            placeholder="Your email address"
            formControlName="email"
            [(ngModel)]="email"
            [ngClass]="{
              'has-error': !hotDealsForm.get('email').valid && !formValid
            }"
            [attr.disabled]="formSending ? '' : null"
          />
        </div>
        <div class="col-12 col-sm-4 padding-none">
          <button
            type="button"
            class="btn"
            (click)="sendHotDeal()"
            [attr.disabled]="formSending ? '' : null"
          >
            {{ formSending === false ? "Send Me Deals" : "Loading..." }}
          </button>
        </div>
      </form>
    </div>
    <!-- /.row -->
    <div class="row" *ngIf="formSent == true">
      <div class="col-md-12 col-lg-12 text-center">
        <h4>You’re all set!</h4>
      </div>
    </div>
  </div>
  <!-- /.container -->
</section>
