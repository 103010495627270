<section id="services">
  <div class="container col-xl-12 px-3 py-4">

    <div class="row featurette align-items-center justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left">
        <p class="featurette-heading"><span>Deep Discounts</span> On Major Airlines, Cruise Lines & Hotels</p>
        <p class="lead">Our well established relationships with major consolidators and direct exclusive access to unpublished rates, private fares and promotional offers allow us to provide the most competitive prices for international flights, luxury cruises and hotels.</p>
        <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Get a FREE Quote</a>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/01.png" alt="Services" aria-hidden="true"/></div>
    </div>
  
    <div class="row featurette pt-5 align-items-center justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 order-lg-3 text-center text-lg-left">
        <p class="featurette-heading">How Much Will <span>I Save?</span></p>
        <p class="lead">The cost depends on multiple factors like your travel destinations, dates, and availability. Typically, we can cut down prices by 20-50% from retail, and sometimes even more. Get a quote today, and we'll promptly send you discounted itineraries. </p>
        <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Get a FREE Quote</a>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/02.png" alt="Services" aria-hidden="true"/></div>
    </div>

    <div class="row featurette pt-5 align-items-center justify-content-center">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left">
        <p class="featurette-heading">A Dedicated Travel Expert <span>Never Wait On Hold Again</span></p>
        <p class="lead">Your dedicated travel expert handles all the legwork, from arranging seat assignments and catering to meal preferences, to organizing travel insurance and even ensuring mileage accrual. In essence, our expertise lies in finding ways to help you save money and time!</p>
        <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Request a Consultation</a>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/03.png" alt="Services" aria-hidden="true"/></div>
    </div>

  </div>
</section>
