/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./traveler-email-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./traveler-email-dialog.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_TravelerEmailDialogComponent = [i0.styles];
var RenderType_TravelerEmailDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TravelerEmailDialogComponent, data: {} });
export { RenderType_TravelerEmailDialogComponent as RenderType_TravelerEmailDialogComponent };
export function View_TravelerEmailDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["aria-hidden", "true"], ["class", "modal show"], ["role", "dialog"], ["style", "display: block;"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn-back"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Flight #2"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(14, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(16, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add another flight?"])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "btn mr-1"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn secondary ml-1"], ["type", "submit"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 16).ngClassValid; var currVal_5 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TravelerEmailDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-traveler-email-dialog", [], null, null, null, View_TravelerEmailDialogComponent_0, RenderType_TravelerEmailDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.TravelerEmailDialogComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TravelerEmailDialogComponentNgFactory = i1.ɵccf("app-traveler-email-dialog", i3.TravelerEmailDialogComponent, View_TravelerEmailDialogComponent_Host_0, {}, {}, []);
export { TravelerEmailDialogComponentNgFactory as TravelerEmailDialogComponentNgFactory };
