export const CruiseDestinationsList: Array<string> = [
  'Any',
  'Alaska',
  'Antarctica',
  'Australia/New Zealand',
  'Bahamas',
  'Bermuda',
  'Canada',
  'Caribbean',
  'China/Asia',
  'Europe',
  'Galapagos',
  'Hawaii',
  'Mediterranean',
  'Mexico',
  'New Zealand',
  'Pacific Coast',
  'Panama Canal',
  'River Cruises',
  'River Cruises - Egypt/Africa',
  'River Cruises - Europe',
  'River Cruises - South America',
  'River Cruises - United States',
  'Singapore',
  'Tahiti',
  'Transatlantic',
  'Transpacific',
  'World Cruise'
];
