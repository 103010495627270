import { CruiseDestinationsList } from '../../shared/lists/cruise-destination-list';
import { CruiseDeparturesList } from '../../shared/lists/cruise-departures-list';
import { CruiseLinesList } from '../../shared/lists/cruise-lines-list';
import { cruiseDepartDateList } from './cruise-departs-date-list';

export enum CruiseListFieldsEnum {
  Destination = 'destination',
  DepartDate = 'departDate',
  CruiseLine = 'line',
  DeparturePort = 'departurePort'
}

export interface CruiseFieldsDataListInterface {
  [key: string]: {
    type: CruiseListFieldsEnum,
    text: string,
    list: Array<string> | Array<{shortName: string, value: string}>
  };
}

export const CruiseFieldsData: CruiseFieldsDataListInterface = {
  destination: {type: CruiseListFieldsEnum.Destination, text: 'Select your destination!', list: CruiseDestinationsList},
  departDate: {
    type: CruiseListFieldsEnum.DepartDate,
    text: 'Select your departure date!',
    list: cruiseDepartDateList()
  },
  line: {type: CruiseListFieldsEnum.CruiseLine, text: 'Select your cruise line!', list: CruiseLinesList},
  departurePort: {
    type: CruiseListFieldsEnum.DeparturePort,
    text: 'Select your departure port!',
    list: CruiseDeparturesList
  }
};
