<!-- Navigation -->
<nav id="main-header" class="main-block navbar navbar-expand">
  <div class="container">
    <a class="navbar-brand-logo" routerLink="{{isBusinessLand? '/home' : '/first-class'}}">
<!--      <picture class="animated fadeIn">-->
<!--        <source [attr.defaultImage]="cdnPath + '/assets/img/Rectangle.webp'" [attr.lazyLoad]="cdnPath + '/assets/img/logo1.webp'" type="image/webp">-->
        <img [src]=" cdnPath + '/assets/img/logo.svg'" class="logo-desc" />
<!--      </picture>-->
      <img [src]="cdnPath + '/assets/img/logo-mob.svg'" class="logo-mob"/>
    </a>
    <div class="collapse navbar-collapse">
     
      <div *ngIf="phoneVisible" class="phone-number-header ml-auto">
        <a class="d-none d-lg-block" href="tel:{{ phone }}">
          <i class="fa fa-phone fa-lg" aria-hidden="true"></i>{{ phone | phone }}</a>
        <a class="d-lg-none visible-phone d-flex align-items-center" href="tel:{{ phone }}">
          <i class="fa fa-phone fa-lg" aria-hidden="true"></i> <b>Call Now</b></a>
      </div>

      <div *ngIf="phoneVisible" class="d-none d-sm-block">
        <span class="phone-or">or</span>
      </div>

      <div class="call-me-button" [ngClass]="{ 'ml-auto': !phoneVisible }">
        <!-- Button trigger modal -->
        <button type="button" class="btn" (click)="openCallMeDialog()">
          Call Back
        </button>
      </div>

      <input #navMenuCheckbox type="checkbox" id="nav-right-toggle" hidden />
      <label (click)="showMenu()"
        for="nav-right-toggle"
        class="nav-right-toggle"
        [ngClass]="{ 'right-align': !phoneVisible }"
      ></label>
      <div class="nav-right">
        <ul (click)="hideMenu()">
          <li>
            <a routerLink="{{isBusinessLand? '/home' : '/first-class'}}">Home</a>
          </li>
          <li>
            <a routerLink="{{buildLink('flights', isBusinessLand)}}">Flights</a>
          </li>
          <li>
            <a href="https://businessclassguru.zenhotels.com/" target="_blank" rel="noopener noreferrer">Hotels</a>
          </li>
          <li>
            <a routerLink="/cruise">Cruises</a>
          </li>
          <li>
            <a routerLink="/corporate-private">Private Jets</a>
          </li>
          <li>
            <a routerLink="our-team">Our Team</a>
          </li>
<!--          <li>-->
<!--            <a routerLink="reviews">Reviews</a>-->
<!--          </li>-->
          <li>
            <a routerLink="app-page" class="nav-app">App</a>
          </li>
          <li>
            <a routerLink="corporate">Corporate</a>
          </li>
          <li>
            <a routerLink="about-us">About</a>
          </li>
          <li>
            <a routerLink="contact-us">Contact</a>
          </li>
        </ul>
        <a class="navbar-brand d-none d-sm-block" href="/">
          <img src="{{ cdnPath }}/assets/img/logo-mob.png" />
        </a>
      </div>
      <div class="mask-content"></div>
    </div>
  </div>
</nav>
