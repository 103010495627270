import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-traveler-email-dialog',
  templateUrl: './traveler-email-dialog.component.html',
  styleUrls: ['./traveler-email-dialog.component.scss']
})
export class TravelerEmailDialogComponent implements OnInit {
  constructor(
      private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.activeModal.close();
  }
}
