/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./get-quote-options-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./get-quote-options-dialog.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../services/searchbox-cruise-mobile.service";
var styles_GetQuoteOptionsDialogComponent = [i0.styles];
var RenderType_GetQuoteOptionsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GetQuoteOptionsDialogComponent, data: {} });
export { RenderType_GetQuoteOptionsDialogComponent as RenderType_GetQuoteOptionsDialogComponent };
function View_GetQuoteOptionsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedTravelers = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedTravelers === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_GetQuoteOptionsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedRooms = _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["id", "BUSINESS"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedRooms === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
export function View_GetQuoteOptionsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["aria-hidden", "true"], ["class", "modal show"], ["role", "dialog"], ["style", "display: block;"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn-back"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cruise options"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Travelers"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GetQuoteOptionsDialogComponent_1)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "list-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rooms"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GetQuoteOptionsDialogComponent_2)), i1.ɵdid(21, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "btn"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveCloseDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.travelersRoomsList; _ck(_v, 16, 0, currVal_0); var currVal_1 = _co.travelersRoomsList; _ck(_v, 21, 0, currVal_1); }, null); }
export function View_GetQuoteOptionsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-get-quote-options-dialog", [], null, null, null, View_GetQuoteOptionsDialogComponent_0, RenderType_GetQuoteOptionsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.GetQuoteOptionsDialogComponent, [i4.NgbActiveModal, i5.SearchboxCruiseMobileService, i4.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GetQuoteOptionsDialogComponentNgFactory = i1.ɵccf("app-get-quote-options-dialog", i3.GetQuoteOptionsDialogComponent, View_GetQuoteOptionsDialogComponent_Host_0, {}, {}, []);
export { GetQuoteOptionsDialogComponentNgFactory as GetQuoteOptionsDialogComponentNgFactory };
