import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as _ from 'lodash';

import {RestResponse} from '../../../model/rest-response';
import {ContactData} from '../../../model/contact-data';
import {environment} from '../../../../environments/environment';
import {QueryHotelParams} from "../../../model/query-hotel-params";
import {QueryCruiseParams} from "../../../model/query-cruise-params";
import { CruiseLinesList } from "../../../../shared/lists/cruise-lines-list";
import {QueryFlightParams} from '../../../model/query-flight-params';
import { ContactDataCruiseInterface } from "../../../model/contact-data-cruise.interface";

@Injectable()
export class SearchboxService {

  constructor(private http: HttpClient) {
  }

  getEnum(param: string): Observable<any> {
    const params = new HttpParams().set('enumName', param);
    return this.http.get(environment.serverUrl + '/get-enum', {params: params});
  }

  getLocations(data: string) {
    const params = new HttpParams().set('term', data);
    return this.http.get(environment.serverUrl + '/cities/get-by-query', {params: params});
  }

  getHotelLocations(data: string) {
    const params: HttpParams = new HttpParams().set('term', data);
    return this.http.get(environment.serverUrl + '/cities/get-names-by-query', {params: params});
  }

  submitSearch(queryFlightParams: QueryFlightParams): Observable<RestResponse> {
    return this.http.post<RestResponse>(environment.serverUrl + '/flight/query-flight', queryFlightParams);
  }

  sendRequest(contactData: ContactData): Observable<RestResponse> {
    return this.http.post<RestResponse>(environment.serverUrl + '/contact-info/get-quote', contactData);
  }

  getCityByDest(dest: string): Observable<RestResponse> {
    const params = new HttpParams().set('destIATA', dest);
    return this.http.get<RestResponse>(environment.serverUrl + '/cities/get-by-dest', {params: params});
  }

  getInitContactData(): Observable<RestResponse> {
    return this.http.get<RestResponse>(environment.serverUrl + '/contact-info/get-info');
  }

  getInitQueryFlightParams(): Observable<QueryFlightParams> {
    return this.http.get<QueryFlightParams>(environment.serverUrl + '/flight/get-search-params');
  }

  getInitQueryBookingParams(): Observable<QueryFlightParams> {
    return this.http.get<QueryFlightParams>(environment.serverUrl + '/flight/get-booking-params');
  }

  // TODO: rename
  public submitMockHotelsSearch(queryHotelParams: QueryHotelParams, contactData: ContactData): Observable<any> {
    return this.http.post<RestResponse>(environment.serverUrl + '/hotel/get-quote', {queryHotelParams: queryHotelParams, contactData: contactData});
  }

  public submitCruiseSearch(queryCruiseParams: QueryCruiseParams, contactData: ContactData | ContactDataCruiseInterface, modifyLine: boolean = true): Observable<any> {
    queryCruiseParams = _.clone(queryCruiseParams);
    if (queryCruiseParams.line && modifyLine) {
      queryCruiseParams.line = CruiseLinesList.find((item: { shortName: string, value: string }): boolean => item.shortName === queryCruiseParams.line).value;
    }
    return this.http.post<RestResponse>(environment.serverUrl + '/cruise/get-quote', {queryCruiseParams, contactData});
  }
}
