import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class GuruInsiderSpecialsService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
    }
    sendEmail(email) {
        return this.http.post(environment.serverUrl + '/guru_insider_specials_sign_up', email);
    }
}
GuruInsiderSpecialsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuruInsiderSpecialsService_Factory() { return new GuruInsiderSpecialsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: GuruInsiderSpecialsService, providedIn: "root" });
