import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {DataWrapper} from '../app/model/data-wrapper';

@Injectable()
export class DataService {
  private subject = new Subject<DataWrapper>();

  sendData(wrapper: DataWrapper) {
    this.subject.next(wrapper);
  }

  clearData() {
    this.subject.next();
  }

  getData(): Observable<DataWrapper> {
    return this.subject.asObservable();
  }
}
