import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {RestResponse} from '../../../model/rest-response';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GuruInsiderSpecialsService {

  constructor(private http: HttpClient, private router: Router) { }

  sendEmail(email: string): Observable<RestResponse> {
    return this.http.post<RestResponse>(environment.serverUrl + '/guru_insider_specials_sign_up', email);
  }
}
