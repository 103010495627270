import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SearchboxCruiseMobileService } from '../../../services/searchbox-cruise-mobile.service';
import { CruiseQueryDataInterface } from '../../../model/cruise-query-data.interface';

@Component({
  selector: 'app-get-quote-options-dialog',
  templateUrl: './get-quote-options-dialog.component.html',
  styleUrls: ['./get-quote-options-dialog.component.scss']
})
export class GetQuoteOptionsDialogComponent implements OnInit {
  public travelersRoomsList: Array<number> = Array.from({length: 10}, (_, index) => index + 1);
  public readonly cruiseQuoteData: CruiseQueryDataInterface;
  public selectedRooms: number;
  public selectedTravelers: number;

  constructor(
      private activeModal: NgbActiveModal,
      private cruiseMobileService: SearchboxCruiseMobileService,
      private modalService: NgbModal
  ) {
    this.cruiseQuoteData = this.cruiseMobileService.getCruiseQuoteData();
    this.selectedRooms = this.cruiseQuoteData.queryCruiseParams.rooms;
    this.selectedTravelers = this.cruiseQuoteData.queryCruiseParams.travelers;
  }

  public ngOnInit(): void {
  }

  public closeAll(): void {
    this.modalService.dismissAll();
  }

  public moveBack(): void {
    this.activeModal.close();
  }

  public saveCloseDialog(): void {
    this.setRooms(this.selectedRooms);
    this.setTravelers(this.selectedTravelers);
    this.activeModal.close({ isSaved: true });
  }

  public setRooms(roomsNumber: number): void {
    this.cruiseMobileService.setRooms(roomsNumber);
  }

  public setTravelers(travelersNumber: number): void {
    this.cruiseMobileService.setTravelers(travelersNumber);
  }
}
