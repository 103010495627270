import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PopularFlightsService {
    constructor(http) {
        this.http = http;
    }
    getPopular(type) {
        return this.http.get(environment.serverUrl + '/custom-page/get-by-type-and-popular', { params: { 'type': type, 'popular': 'true', 'site': 'bcg' } });
    }
}
PopularFlightsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PopularFlightsService_Factory() { return new PopularFlightsService(i0.ɵɵinject(i1.HttpClient)); }, token: PopularFlightsService, providedIn: "root" });
