import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss']
})
export class ContactUsDialogComponent implements OnInit {

  text: string;
  success: boolean;
  cdnPath: string;

  constructor(private dialogRef: MatDialogRef<ContactUsDialogComponent>) {
  }

  ngOnInit() {
    this.cdnPath = environment.cdnPath;
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
